<template>
  <v-dialog
    v-model="dialog"
    content-class="role-description-popup"
    transition="dialog-bottom-transition"
    max-width="var(--modal-window-narrow-width)"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" @mouseover="dialog = true" class="role-description-popup__activator" small dark>
        mdi-help-circle-outline
      </v-icon>
    </template>
    <div class="content">
      <v-btn icon large @click="dialog = false" :title="$t('common.closeWindowHoverTitle')" class="close-btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-list disabled>
        <v-list-item v-for="(role, i) in roles" :key="i">
          <v-list-item-content>
            <template>
              <div v-if="isPresentor(role)" class="mb-2">
                <span class="wx-typo-md font-weight-bold primary--text">{{ $t("user.roles.presentation") }}</span>
                <span class="wx-typo-md font-weight-light ml-2">{{ $t("user.wizard.tooltipForRoleDisplay1") }}</span
                ><br />
                <ul class="step-list wx-typo-md font-weight-light mt-2 mb-4">
                  <li>{{ $t("user.wizard.tooltipForRoleDisplay2") }}</li>
                  <li>{{ $t("user.wizard.tooltipForRoleDisplay3") }}</li>
                  <li>{{ $t("user.wizard.tooltipForRoleDisplay4") }}</li>
                </ul>
              </div>
              <div v-if="isOperation(role)" class="mb-2">
                <span class="wx-typo-md font-weight-bold primary--text">{{ $t("user.roles.operation") }}</span>
                <span class="wx-typo-md font-weight-light ml-2">{{ $t("user.wizard.tooltipForRoleOperation1") }}</span
                ><br />
                <ul class="step-list wx-typo-md font-weight-light mt-2 mb-2">
                  <li>{{ $t("user.wizard.tooltipForRoleOperation2") }}</li>
                  <li>{{ $t("user.wizard.tooltipForRoleOperation3") }}</li>
                </ul>
                <ul class="step-list wx-typo-md font-weight-light mt-2 mb-4">
                  <li>
                    <span class="font-weight-bold mr-1">{{ $t("user.wizard.tooltipForRoleOperation4_1") }}</span>
                    <span>{{ $t("user.wizard.tooltipForRoleOperation4_2") }}</span>
                  </li>
                  <li>
                    <span class="font-weight-bold mr-1">{{ $t("user.wizard.tooltipForRoleOperation5_1") }}</span>
                    <span>{{ $t("user.wizard.tooltipForRoleOperation5_2") }}</span>
                  </li>
                </ul>
              </div>
              <div v-if="isManagement(role)" class="mb-2">
                <span class="wx-typo-md font-weight-bold primary--text">{{ $t("user.roles.management") }}</span>
                <span class="wx-typo-md font-weight-light ml-2">{{ $t("user.wizard.tooltipForRoleManagement1") }}</span>
                <ul class="step-list wx-typo-md font-weight-light mt-2 mb-4">
                  <li>{{ $t("user.wizard.tooltipForRoleManagement2") }}</li>
                  <li>{{ $t("user.wizard.tooltipForRoleManagement3") }}</li>
                </ul>
              </div>
            </template>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-dialog>
</template>

<script>
import { DIRECTOR_ROLE, PRESENTER_ROLE, SUPERVISOR_ROLE } from "@/store/modules/user";

export default {
  name: "RoleDescriptionPopup",
  data() {
    return {
      dialog: false,
      roles: [PRESENTER_ROLE, SUPERVISOR_ROLE, DIRECTOR_ROLE],
    };
  },
  methods: {
    isPresentor(role) {
      return role === PRESENTER_ROLE;
    },
    isOperation(role) {
      return role === SUPERVISOR_ROLE;
    },
    isManagement(role) {
      return role === DIRECTOR_ROLE;
    },
  },
};
</script>

<style lang="scss" scoped>
.role-description-popup {
  &__activator {
    margin-top: -2px;
    opacity: 0.6;
  }
}

.content {
  position: relative;
  width: auto;
  max-width: 100%;
  padding: var(--dialog-small-padding);
  background-color: var(--color-flat-panel-modal-theme);
}

.step-list {
  list-style: initial;
  padding-left: 24px;
}

.v-list {
  background-color: var(--color-flat-panel-modal-theme);

  @media ($wx-sm-min) {
    display: flex;
    flex-flow: row wrap;
    padding: 4px 0;
  }

  @media ($wx-md-min) {
    padding: 8px 0;
  }

  .v-list-item {
    padding: 4px;
    min-height: 14px;

    @media ($wx-sm-min) {
      width: 100%;
      flex: 0 0 auto;
    }

    @media ($wx-md-min) {
      min-height: 16px;
    }

    @media ($wx-lg-min) {
      min-height: 19px;
    }

    @media ($wx-xl-min) {
      min-height: 22px;
    }

    .v-list-item__content {
      padding: 0;
    }
  }
}
</style>
