<template>
  <fragment>
    <h1 class="mb-0 pb-0">{{ $t("user.title") }}</h1>
    <span v-if="isMultipleFactories" class="wx-typo-sm">{{ $t("user.wizard.subTitle") }}</span>
    <div v-show="showWizard" class="mt-5">
      <user-wizard :initial-user="initialUser" />
    </div>
  </fragment>
</template>

<script>
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";
import UserService from "@/components/user/UserService";
import UserWizard from "../components/user/UserWizard";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    UserWizard,
  },
  data() {
    return {
      initialUser: null,
      showWizard: false,
    };
  },
  computed: {
    ...mapGetters("user", ["isMultipleFactories"]),
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    async fetchUser(userId) {
      UserService.getUser(userId)
        .then((response) => this.handleUserRetrievalResponse(response))
        .catch((error) => this.handleUserRetrievalError(error.response));
    },
    handleUserRetrievalResponse(httpResponse) {
      if (httpResponse.status === 200) {
        this.initialUser = httpResponse.data;
        this.showWizard = true;
      }
    },
    handleUserRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.$router.push(RouteService.toUsers());
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
  },
  async mounted() {
    const userId = this.$route.params.id;
    if (userId) {
      await this.fetchUser(this.$route.params.id);
    } else {
      this.showWizard = true;
    }
  },
};
</script>
